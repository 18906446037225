import React from 'react'

export default function AboutScreen() {
    return (
        <div>
            <div id="about-section">
                <div>
                <h1>Short about Leafy</h1>
                <p className="aboutleafy-text">
                Leafy is a platform that at the moment focuses on selling plants. Leafy is currently developing the new supersmart application
                where customers can get plants recommended to them based on their desired environment. Leafy is also looking to gene modify 
                plants so that the customer can later be able to customize their own tree. 
                </p>
                </div>
                <div className="aboutus-section">
                <ul id="about-list">
                <li id="memb"><img src="../images/sid.gif" alt="Adrian.jpg"></img><h2>Adrian</h2>
                <p>Adrian has focused on the backend tasks such as setting-up the database, designing the REST API and deploying the app to Heroku. </p>
                <p>Feel free to contact me at: sergheevdev@icloud.com</p></li>
                <li id="memb"><img src="../images/inaki.jpg" alt="Iñaki.jpg"></img><h2>Iñaki</h2>
                <p>Iñaki was responsible for the sign up / register and all of the administration options and frontend of those pages. </p>
                <p>Feel free to contact me at: inaki.zabaleta003@stud.hkr.se</p></li>
                <li id="memb"><img src="../images/jonas.jpg" alt="Jonas.jpg"></img><h2>Jonas</h2>
                <p>Jonas has setup a realtime database and been working with the connections in between the database to the frontend of the application.</p>
                <p>Feel free to contact me at: jonas.johansson0280@stud.hkr.se</p></li>
                <li id="memb"><img src="../images/grimreaper.jpg" alt="Viktor.jpg"></img><h2>Viktor Westlund</h2>
                <p>Viktor has been working with frontend in this project with the focus on creating a fully responsive web design.</p>
                <p>Feel free to contact me at: viktorwestlund@outlook.com</p></li>
                </ul>
                </div>
            </div>
        </div>
    )
}
