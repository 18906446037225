import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import CheckoutSteps from '../components/CheckoutSteps'
import { cartReducer } from '../reducers/cartReducers'

export default function PlaceOrderScreen(props) {
    const cart = useSelector(state => state.cart)
    if (!cart.paymentMethod) {
        props.history.push('/payment')
    }
    const toPrice = (num) => Number(num.toFixed(2));
    cart.itemsPrice = toPrice(
        cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0)
        );
    cart.shippingPrice = cart.itemsPrice > 100? toPrice(0) : toPrice(3)
    cart.totalPrice = cart.itemsPrice + cart.shippingPrice;
        const placeOrderHandler = () => {
            // dispatch place order action
        }
    return (
        <div>
            <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>
            <div className="row top">
                <div className="col-2">
                    <ul>
                        <li>
                            <div className="card card-body">
                                <h2>Shipping</h2>
                                <p>
                                    <strong>Name: </strong> {cart.shippingAddress.fullName} <br/>
                                    <strong>Address: </strong> {cart.shippingAddress.address}, 
                                    {cart.shippingAddress.city}, {cart.shippingAddress.postalCode}, 
                                    {cart.shippingAddress.country}
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="card card-body">
                                <h2>Payment</h2>
                                <p>
                                    <strong>Name: </strong> {cart.paymentMethod}
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="card card-body">
                                <h2>Order Items</h2>
                                <ul>
                    {cart.cartItems.map((product) => (
                        <li key={product.product}>
                            <div className="row">
                                <div>
                                    <img src={product.image} alt={product.name} className="small"></img>
                                </div>
                                <div className="min-30">
                                    <Link to={`/product/${product.product}`}>{product.name}</Link>
                                </div>
                                <div>
                                    {product.qty} x ${product.price} = ${product.qty * product.price}
                                </div>
                            </div>
                        </li>
                    )
                    )
                    }
                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-1">
                    <div className="card card-body">
                        <li>
                            <h2>
                                Order Summary
                            </h2>
                        </li>
                        <li>
                            <div className="row">
                            <div>Items</div>
                            <div>${cart.itemsPrice}</div>
                            </div>
                        </li>
                        <li>
                            <div className="row">
                                <div>Shipping</div>
                                <div>${cart.shippingPrice}</div>
                            </div>
                        </li>
                        <li>
                            <div className="row">
                                <div>
                                    <strong>Order Total</strong>
                                </div>
                                <div>
                                    <strong>${cart.totalPrice}</strong>
                                </div>
                            </div>
                        </li>
                        <li>
                            <button 
                            type="button" 
                            onClick={placeOrderHandler} 
                            className="primary block"
                            disabled={cart.cartItems.length === 0}
                            >Place Order</button>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    )
}
