import React from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import AdminRoute from "./components/AdminRoute";
import AboutScreen from "./screens/AboutScreen";
import CartScreen from "./screens/CartScreen";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import ProductListScreen from "./screens/ProductListScreen";
import store from "./store";
import SigninScreen from "./screens/SigninScreen";
import RegisterScreen from "./screens/RegisterScreen";
import { Link } from "react-router-dom";
import { signout } from "./actions/userActions";
import ProductEditScreen from "./screens/ProductEditScreen";
import ShippingAddressScreen from "./screens/ShippingAddressScreen";
import PaymentMethodScreen from "./screens/PaymentMethodScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";

function App() {
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  };
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="grid-container">
          <header className="row">
            <div>
              <a className="brand" href="/">
                Leafy
              </a>
            </div>
            <div>
              <Link to="/about">About</Link>
              <Link to="/cart">Cart</Link>
              {cartItems.length > 0 && (
                <span className="badge">{cartItems.length}</span>
              )}
              {userInfo ? (
                <div className="dropdown">
                  <Link to="#">
                    {" "}
                    {userInfo.name} <i className="fa fa-caret-down"></i>{" "}
                  </Link>
                  <ul className="dropdown-content">
                    <Link to="#signout" onClick={signoutHandler}>Sign Out</Link>
                    {userInfo && userInfo.isAdmin && (
                      <li>
                        <Link to="/productlist" id="prodEdit">Edit Products</Link>
                      </li>
                    )}
                  </ul>
                </div>
              ) : (
                <Link to="/signin">Sign In </Link>
              )}
            </div>
          </header>
          <main>
            <Route path="/about" component={AboutScreen}/>
            <Route path="/cart/:id?" component={CartScreen}/>
            <Route path="/product/:id" component={ProductScreen} exact/>
            <AdminRoute
              path="/product/:id/edit"
              component={ProductEditScreen}
              exact
            />
            <Route path="/signin" component={SigninScreen}/>
            <Route path="/register" component={RegisterScreen}/>
            <Route path="/" component={HomeScreen} exact/>
            <AdminRoute
              path="/productlist"
              component={ProductListScreen}
            />
            <Route path="/shipping" component={ShippingAddressScreen}/>
            <Route path="/payment" component={PaymentMethodScreen}/>
            <Route path="/placeorder" component={PlaceOrderScreen}/>
          </main>

          <footer className="row center">
            All rights reserved © Leafy Inc.
          </footer>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
