import Axios from 'axios';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_SAVE_PAYMENT_METHOD, CART_SAVE_SHIPPING_ADDRESS } from "../constants/cartConstants";



export const addToCart = (productId, qty) => async(dispatch, getState) => {
    const {data} = await Axios.get(`https://vast-forest-17536.herokuapp.com/items/${productId}`);
    const {
        cart: { cartItems },
    } = getState();

    dispatch({
        type: CART_ADD_ITEM,
        payload:{
            name: data.name,
            image: data.photoURL,
            price: data.price,
            countInStock: data.stock,
            product: data.id,
            qty,
        },
    });
    localStorage.setItem(
        'cartItems',
        JSON.stringify(getState().cart.cartItems)
    )
};
export const removeFromCart = (productId) => (dispatch, getState) => {
    dispatch({ type: CART_REMOVE_ITEM, payload: productId });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({type: CART_SAVE_SHIPPING_ADDRESS, payload: data})
    localStorage.setItem('shippingAddress', JSON.stringify(data))
}
export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({type: CART_SAVE_PAYMENT_METHOD, payload: data})
}