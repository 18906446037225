import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_SAVE_PAYMENT_METHOD, CART_SAVE_SHIPPING_ADDRESS } from "../constants/cartConstants";

export const cartReducer = (state = { cartItems:[]}, action) => {
    switch(action.type){
        case CART_ADD_ITEM:
            const item = action.payload;
            const alreadyInCart = state.cartItems.find(x => x.product === item.product);
            if(alreadyInCart){
                return{
                    ...state,
                    cartItems: state.cartItems.map(x =>
                        x.product === alreadyInCart.product? item: x),
                };
            } else { //item is new
                return {...state, cartItems: [...state.cartItems, item]};
            }
        case CART_REMOVE_ITEM:
            return {
                ...state,
                error: '',
                cartItems: state.cartItems.filter((x) => x.product !== action.payload),

            };
        case CART_SAVE_SHIPPING_ADDRESS: 
            return {
                ...state, shippingAddress: action.payload
            }
        case CART_SAVE_PAYMENT_METHOD:
            return {
                ...state, paymentMethod: action.payload
            }
        default:
            return state;
    }
};