import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  deleteProduct,
  listProducts,
} from "../actions/productActions";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_RESET,
} from "../constants/productConstants";

export default function ProductListScreen(props) {
  const productList = useSelector((state) => state.productList);
  const { products, loading } = productList;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const dispatch = useDispatch();

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  useEffect(() => {
    if (successCreate) {
      dispatch({ type: PRODUCT_CREATE_RESET });
      props.history.push(`/productlist`);
    }
    if (successDelete) {
      dispatch({ type: PRODUCT_DELETE_RESET });
    }
    dispatch(listProducts());
  }, [createdProduct, dispatch, props.history, successCreate, successDelete]);

  

  const deleteHandler = (product) => {
    if (window.confirm(`Are you sure you want to delete ${product.name}?`)) {
      dispatch(deleteProduct(product.id));
      props.history.push('/productlist');
      
    }
  };

  const createHandler = () => {
    dispatch(createProduct());
      props.history.push('/productlist');
  };
  return (
    <div>
      <div className="row">
        <h1 id="prodlist-header">Products</h1>
        <button type="button" className="primary" onClick={createHandler}>
          Create Product
        </button>
      </div>
      {successCreate || successDelete || loading ? (
        <div className="loading">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="productlist-content">
        <table className="table tablemobile">
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>PRICE</th>
              <th>CATEGORY</th>
              <th>SUBCATEGORY</th>
              <th>STOCK</th>
              <th>AMOUNT SOLD</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.name}</td>
                <td>{product.price}</td>
                <td>{product.category}</td>
                <td>{product.subcategory}</td>
                <td>{product.stock}</td>
                <td>{product.amountSold}</td>
                <td>
                  <button
                    type="button"
                    className="small"
                    onClick={
                      () => props.history.push(`/product/${product.id}/edit`) //id might be wrong
                    }
                  >
                    Edit
                  </button>

                  <button
                    type="button"
                    className="small"
                    onClick={() => deleteHandler(product)
                    }
                    >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
    </div>
  );
}
