import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsProduct, updateProduct } from "../actions/productActions";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";

export default function ProductEditScreen(props) {
  const productId = props.match.params.id;
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [category, setCategory] = useState("");
  const [stock, setStock] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [amountSold, setAmountSold] = useState("");

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const refreshPage = ()=>{
    window.location.reload();
 }

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const dispatch = useDispatch();
  useEffect(() => {
    if (successUpdate) {
      props.history.push('/productlist');
    }
    if (!product || successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      dispatch(detailsProduct(productId));
    } else {
      if (!productId){
        productId = "ID does not exist, please try again"
      }
      else if (parseInt(product.id) !== parseInt(productId)){
        console.log(product.id + productId)
        refreshPage()
      }
      setName(product.name);
      setPrice(product.price);
      setPhotoURL(product.photoURL);
      setCategory(product.category);
      setStock(product.stock);
      setSubcategory(product.subcategory);
      setAmountSold(product.amountSold);
    }
  }, [product, dispatch, productId, successUpdate, props.history]);
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        id: productId,
        name,
        category,
        subcategory,
        photoURL,
        price,
        stock,
        amountSold,
      })
    );
  };
  return (
    <div>
      <form className="form" onSubmit={submitHandler}>
        <div>
          <h1>Edit Product ID: {productId}</h1>
        </div>
        {loading ? (
        <div className="loading">
          <h1>Loading...</h1>
          
        </div>
      ) : (
          <>
            <div>
              <label htmlFor="name">Name</label>
              <input
                id="name"
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="price">Price</label>
              <input
                id="price"
                type="text"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="photoURL">PhotoURL</label>
              <input
                id="photoURL"
                type="text"
                placeholder="Enter photoURL"
                value={photoURL}
                onChange={(e) => setPhotoURL(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="category">Category</label>
              <input
                id="category"
                type="text"
                placeholder="Enter category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="subcategory">Subcategory</label>
              <input
                id="subcategory"
                type="text"
                placeholder="Enter subcategory"
                value={subcategory}
                onChange={(e) => setSubcategory(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="stock">Count In Stock</label>
              <input
                id="stock"
                type="text"
                placeholder="Enter stock"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="amountSold">Amount Sold</label>
              <textarea
                id="amountSold"
                rows="1"
                type="text"
                placeholder="Enter amount sold"
                value={amountSold}
                onChange={(e) => setAmountSold(e.target.value)}
              ></textarea>
            </div>
            <div>
              <label></label>
              <button className="primary" type="submit"
              >
                Update
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}