import axios from "axios";
//import { useSelector } from "react-redux";
import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
} from "../constants/productConstants";


export const listProducts = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(
      "https://vast-forest-17536.herokuapp.com/items/"
    );
    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
  }
};

export const detailsProduct = (productId) => async (dispatch) => {
  dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
  try {
    const { data } = await axios.get(
      `https://vast-forest-17536.herokuapp.com/items/${productId}`
    );
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProduct = () => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_CREATE_REQUEST })
  const retUserInfo = JSON.parse(localStorage.getItem("userInfo"))
  const userpassword = localStorage.getItem("userPassword")
  var data = JSON.stringify({
    "password": userpassword,
    "email": retUserInfo.email
  })
  var config = {
    method: 'post',
    url: `https://vast-forest-17536.herokuapp.com/items/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data : data
  };

  /*dispatch({ type: PRODUCT_CREATE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState(); 
  const userInfoJSON = [{
    password: userpassword,
    email: userInfo.email,
    isAdmin: userInfo.isAdmin
  }] */
    try {
      axios(config)
      dispatch({
        type: PRODUCT_CREATE_SUCCESS,
        payload: data.product,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_CREATE_FAIL, payload: message });
    }
};

export const updateProduct = (product) => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_UPDATE_REQUEST, payload: product })
  /*dispatch({ type: PRODUCT_UPDATE_REQUEST, payload: product });
  const {
    userSignin: { userInfo },
  } = getState(); */
  const retUserInfo = JSON.parse(localStorage.getItem("userInfo"))
  const userpassword = localStorage.getItem("userPassword")
  var data = JSON.stringify({
    "password": userpassword,
    "email": retUserInfo.email,
    "name": product.name,
    "category": product.category,
    "subcategory": product.subcategory,
    "photoURL": product.photoURL,
    "price": product.price,
    "stock": product.stock,
    "amountSold": product.amountSold
  })
  console.log(product.name)
  var config = {
    method: 'post',
    url: `https://vast-forest-17536.herokuapp.com/items/${product.id}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    axios(config)
    dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_UPDATE_FAIL, error: message });
  }
};

export const deleteProduct = (productId) => async (dispatch, getState) => {
  const retUserInfo = JSON.parse(localStorage.getItem("userInfo"))
  // console.log(retUserInfo.email)
  
  const userpassword = localStorage.getItem("userPassword")
  // console.log(userpassword)

  dispatch({ type: PRODUCT_DELETE_REQUEST, payload: productId });
  /*const {
    data: { userInfo },} = getState(); */ //authenticator */// console.log(userInfo.password)// const userInfo = {//   password: userpassword,//   email: retUserInfo.email// }
  var data = JSON.stringify({
    "password": userpassword,
    "email": retUserInfo.email
  })
  var config = {
    method: 'delete',
    url: `https://vast-forest-17536.herokuapp.com/items/${productId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    axios(config)
    dispatch({ type: PRODUCT_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_DELETE_FAIL, payload: message });
    console.log(error + message)
  }
};
