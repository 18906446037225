import React, { useEffect } from 'react';
import Product from '../components/Product';
import { useSelector, useDispatch } from 'react-redux'
import { listProducts } from '../actions/productActions';



// export default function HomeScreen() {
//   const dispatch = useDispatch();
//   const productList = useSelector((state) => state.productList);
//   const { loading, products } = productList;

//   useEffect(() => {
//       dispatch(listProducts());
//   }, []) 
//     return (
//         <div>
//         <div className="row center">
//         {products.map((product) => (
//           <Product key={product.id} product={product}></Product>
//         ))}
//         </div>
//       </div>
//     );
// }

export default function HomeScreen() {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, products } = productList;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch])

  return (
    <div>
      { loading ? (
        <div className="loading">
          <h1>Loading...</h1>
        </div>
      ) : (
          <div>
            <div className="row center">
              {products.map((product) => (
                <Product key={product.id} product={product}></Product>
              ))}
            </div>
          </div>
        )}
    </div>
  );
}
