import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { detailsProduct } from '../actions/productActions';

export default function ProductScreen(props) {
    const dispatch = useDispatch()
    const productId = props.match.params.id;
    const [qty, setQty] = useState(1)
    const productDetails = useSelector((state) => state.productDetails);
    const { loading,  product } = productDetails; 
    const addToCartHandler = () => {
        props.history.push(`/cart/${productId}?qty=${qty}`)};

    useEffect(() => { 
        dispatch(detailsProduct(productId))
    }, [dispatch, productId])
    if(!product){
        return <div> Product Not Found </div>
    }
    return (
        <div>
            { loading ? (
                <div className="loading">
                <h1>Loading...</h1>
                </div>
      ) : (    
          <div>
      <a href = "/">Back to result</a>
      <div className="row top">
          <div className="col-2">
              <img className="large" src={product.photoURL} alt={product.name}></img>
          </div>
          <div className="col-1">
              <ul className="col-3">
                  <li>
                      <h1>{product.name}</h1>
                  </li>
                  <div className="row">
                  <li>
                      Price: ${product.price}
                  </li>
                  </div>
              </ul>
          </div>
          <div className="col-1">
              <div className="card card-body">
                  <ul>
                      <li>
                          <div className="row">
                              <div>Price: </div>
                              <div>${product.price}</div>
                          </div>
                      </li>
                      
                      <li>
                          <div className="row">
                              <div>Status: </div>
                              <div>
                                  {product.stock > 0 ? 
                              ( <span className="success"> In Stock</span> )
                              :
                              ( <span className="error">Unavailable</span> )}
                              </div>
                          </div>
                      </li>
                      {
                          product.stock > 0 && (
                          <>
                          <li>
                              <div className = "row">
                              <div>Qty</div>
                              <div>
                                  <select value={qty} onChange={e => setQty(e.target.value)}>
                                      {
                                          [...Array(product.stock).keys()].map( x => (
                                              <option key={x+1} value={x+1}>{x+1}</option>
                                          ))
                                      }
                                  </select>
                              </div>
                              </div>
                          </li>
                      <li>
                          <button onClick={addToCartHandler} className="primary block">Add to Cart</button>
                      </li>
                      </>
                      )
                  }
                  </ul>
                  
              </div>
          </div>
      </div>
  </div>
            )}
        </div>
        
    )
}
